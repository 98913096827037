import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

import { createAuth } from '@redwoodjs/auth-azure-active-directory-web'

import * as variables from 'src/utils/variables'

console.log('Initializing Azure Active Directory Client...')

const azureActiveDirectoryClient = new PublicClientApplication({
  system: {
    // acquireTokenSilent only looks at the expiration time of the access token
    // to determine when it needs to refresh the tokens (because it wrongly
    // assumes all tokens have the same lifetime). Microsoft changed their
    // backend and the access token now have a random lifespan between 60 and 90
    // minutes while the ID token has a fixed 1 hour lifetime (at least by
    // default). This means acquireTokenSilent can return expired ID tokens from
    // cache for at most 30 minutes. To work around this, we set the renewal
    // offset to 35 minutes before the accessToken expires, which will translate
    // to 5 to 35 minutes before the ID token expires
    //
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1246#issuecomment-622044335
    // https://learn.microsoft.com/en-us/entra/identity-platform/configurable-token-lifetimes#access-tokens
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#avoiding-interactive-interruptions-in-the-middle-of-a-users-session
    //
    // redwood/packages/auth-providers/azureActiveDirectory/web/src/azureActiveDirectory.ts

    // An explicit logic to refresh tokens is implemented in HostLayout.tsx
    tokenRenewalOffsetSeconds: 35 * 60,
    loggerOptions: {
      logLevel: LogLevel.Trace,
      loggerCallback: (_level, message, containsPii) => {
        if (containsPii) return // Avoid logging personally identifiable information
        console.error(message)
      },
    },
  },
  auth: {
    clientId: variables.getAzureActiveDirectoryClientId() || '',
    authority: variables.getAzureActiveDirectoryAuthority(),
    redirectUri: variables.getAzureActiveDirectoryRedirectUri(),
    postLogoutRedirectUri: variables.getAzureActiveDirectoryLogoutRedirectUri(),
  },
})

console.log(
  'Azure Active Directory Client initialized:',
  azureActiveDirectoryClient
)

export const { AuthProvider, useAuth } = createAuth(azureActiveDirectoryClient)

console.log('AuthProvider and useAuth created')
